import React, {useEffect, useState} from 'react'
import {Footer, Header} from "../components";
import PagesContent from "../components/pages/Pages";


const ConditionsPage = () => {

    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll)
    }, []);


    return (
        <div>
            <Header/>

            <PagesContent page_name="Conditions d’utilisation" />

            <Footer/>
        </div>
    )
}

export default ConditionsPage